export const LIST_COLOR = [
    {
        ID: '1',
        NAME: 'White',
        CODE: 'white',
        HEX: 'ffffff'
    },
    {
        ID: '2',
        NAME: 'Black',
        CODE: 'black',
        HEX: '000000'
    },
    {
        ID: '3',
        NAME: 'Azalea',
        CODE: 'azalea',
        HEX: 'd42e5b'
    },
    {
        ID: '4',
        NAME: 'Berry',
        CODE: 'berry',
        HEX: '990f4b'
    },
    {
        ID: '5',
        NAME: 'Brown Savana',
        CODE: 'brown_savana',
        HEX: '786962'
    },
    {
        ID: '6',
        NAME: 'Cardinal',
        CODE: 'cardinal',
        HEX: 'bd2031'
    },
    {
        ID: '7',
        NAME: 'Carolina Blue',
        CODE: 'carolina_blue',
        HEX: '99badd'
    },
    {
        ID: '8',
        NAME: 'Charcoal',
        CODE: 'charcoal',
        HEX: '36454f'
    },
    {
        ID: '9',
        NAME: 'Cornsilk',
        CODE: 'cornsilk',
        HEX: 'fff8dc'
    },
    {
        ID: '10',
        NAME: 'Daisy',
        CODE: 'daisy',
        HEX: 'fff151'
    },
    {
        ID: '11',
        NAME: 'Dark Chocolate',
        CODE: 'dark_chocolate',
        HEX: '271815'
    },
    {
        ID: '12',
        NAME: 'Dark Heather',
        CODE: 'dark_heather',
        HEX: '3d4142'
    },
    {
        ID: '13',
        NAME: 'Forest Green',
        CODE: 'forest_green',
        HEX: '228b22'
    },
    {
        ID: '14',
        NAME: 'Gold',
        CODE: 'gold',
        HEX: 'ffd700'
    },
    {
        ID: '15',
        NAME: 'Graphite Heather',
        CODE: 'graphite_heather',
        HEX: '77787c'
    },
    {
        ID: '16',
        NAME: 'Gravel',
        CODE: 'gravel',
        HEX: '53544e'
    },
    {
        ID: '17',
        NAME: 'Heather Navy',
        CODE: 'heather_navy',
        HEX: '2e3d5c'
    },
    {
        ID: '18',
        NAME: 'Heather Radiant Orchid',
        CODE: 'heather_radiant_orchid',
        HEX: 'ad5e99'
    },
    {
        ID: '19',
        NAME: 'Heather Red',
        CODE: 'heather_red',
        HEX: 'bc3b4f'
    },
    {
        ID: '20',
        NAME: 'Heather Sapphire',
        CODE: 'heather_sapphire',
        HEX: '0291bd'
    },
    {
        ID: '21',
        NAME: 'Heliconia',
        CODE: 'heliconia',
        HEX: 'db3e79'
    },
    {
        ID: '22',
        NAME: 'Indigo Blue',
        CODE: 'indigo_blue',
        HEX: '00368c'
    },
    {
        ID: '23',
        NAME: 'Irish Green',
        CODE: 'irish_green',
        HEX: '009a44'
    },
    {
        ID: '24',
        NAME: 'Kiwi',
        CODE: 'kiwi',
        HEX: '7aab55'
    },
    {
        ID: '25',
        NAME: 'Light Blue',
        CODE: 'light_blue',
        HEX: 'add8e6'
    },
    {
        ID: '26',
        NAME: 'Light Pink',
        CODE: 'light_pink',
        HEX: 'ffb6c1'
    },
    {
        ID: '27',
        NAME: 'Lime',
        CODE: 'lime',
        HEX: '39ec14'
    },
    {
        ID: '28',
        NAME: 'Maroon',
        CODE: 'maroon',
        HEX: '800000'
    },
    {
        ID: '29',
        NAME: 'Military Green',
        CODE: 'military_green',
        HEX: '4b5320'
    },
    {
        ID: '30',
        NAME: 'Natural',
        CODE: 'natural',
        HEX: 'aa907d'
    },
    {
        ID: '31',
        NAME: 'Navy',
        CODE: 'navy',
        HEX: '1a2b56'
    },
    {
        ID: '32',
        NAME: 'Neon Blue',
        CODE: 'neon_blue',
        HEX: '4b4bfb'
    },
    {
        ID: '33',
        NAME: 'Neon Green',
        CODE: 'neon_green',
        HEX: '39ff14'
    },
    {
        ID: '34',
        NAME: 'Orange',
        CODE: 'orange',
        HEX: 'ff5f1f'
    },
    {
        ID: '35',
        NAME: 'Purple',
        CODE: 'purple',
        HEX: 'cc8899'
    },
    {
        ID: '36',
        NAME: 'red',
        CODE: 'red',
        HEX: 'ff0000'
    },
    {
        ID: '37',
        NAME: 'Royal',
        CODE: 'royal',
        HEX: '04385d'
    },
    {
        ID: '38',
        NAME: 'Safety Green',
        CODE: 'safety_green',
        HEX: 'f1fb42'
    },
    {
        ID: '39',
        NAME: 'Safety Orange',
        CODE: 'safety_orange',
        HEX: 'ff6700'
    },
    {
        ID: '40',
        NAME: 'Sand',
        CODE: 'sand',
        HEX: 'c2b280'
    },
    {
        ID: '41',
        NAME: 'Sapphire',
        CODE: 'sapphire',
        HEX: '0f52ba'
    },
    {
        ID: '42',
        NAME: 'Sky',
        CODE: 'sky',
        HEX: '87ceeb'
    },
    {
        ID: '44',
        NAME: 'Texas Orange',
        CODE: 'texas_orange',
        HEX: 'd35a05'
    },
    {
        ID: '45',
        NAME: 'Turf Green',
        CODE: 'turf_green',
        HEX: '19a23a'
    },
    {
        ID: '46',
        NAME: 'Violet',
        CODE: 'violet',
        HEX: 'cf9fff'
    },
    {
        ID: '47',
        NAME: 'Yellow Haze',
        CODE: 'yellow_haze',
        HEX: 'fece9d'
    },
    {
        ID: '48',
        NAME: 'Ash',
        CODE: 'ash',
        HEX: 'a29d9c'
    },
    {
        ID: '50',
        NAME: 'Cardinal Red',
        CODE: 'cardinal_red',
        HEX: 'bd2031'
    },
    {
        ID: '51',
        NAME: 'Cherry Red',
        CODE: 'cherry_red',
        HEX: 'd2042d'
    },
    {
        ID: '52',
        NAME: 'Garnet',
        CODE: 'garnet',
        HEX: '9a2a2a'
    },
    {
        ID: '53',
        NAME: 'Legion Blue',
        CODE: 'legion_blue',
        HEX: '1f495b'
    },
    {
        ID: '54',
        NAME: 'Royal Blue',
        CODE: 'royal_blue',
        HEX: '0504aa'
    },
    {
        ID: '55',
        NAME: 'Sport Grey',
        CODE: 'sport_grey',
        HEX: '9b9a9d'
    },
    {
        ID: '56',
        NAME: 'Deep Forest',
        CODE: 'deep_forest',
        HEX: '1f3d0c'
    },
    {
        ID: '57',
        NAME: 'Kelly Green',
        CODE: 'kelly_green',
        HEX: '4cbb17'
    },
    {
        ID: '58',
        NAME: 'Brown',
        CODE: 'brown',
        HEX: '8b4513'
    },
    {
        ID: '59',
        NAME: 'Blue Light',
        CODE: 'blue_light',
        HEX: '42b4e6'
    },
    {
        ID: '60',
        NAME: 'Blue',
        CODE: 'blue',
        HEX: '0057d9'
    },
    {
        ID: '61',
        NAME: 'Creme',
        CODE: 'creme',
        HEX: 'fffdd0'
    },
    {
        ID: '62',
        NAME: 'Dark Heather Grey',
        CODE: 'dark_heather_grey',
        HEX: '575558'
    },
    {
        ID: '63',
        NAME: 'Dark Red',
        CODE: 'dark_red',
        HEX: '8b0000'
    },
    {
        ID: '64',
        NAME: 'Forest',
        CODE: 'forest',
        HEX: '228b22'
    },
    {
        ID: '65',
        NAME: 'Heather Grey',
        CODE: 'heather_grey',
        HEX: '9c9da4'
    },
    {
        ID: '66',
        NAME: 'Kelly',
        CODE: 'kelly',
        HEX: '60af38'
    },
    {
        ID: '67',
        NAME: 'Safety Pink',
        CODE: 'safety_pink',
        HEX: 'ff7fb0'
    },
    {
        ID: '68',
        NAME: 'Smoke Grey',
        CODE: 'smoke_grey',
        HEX: '8995a1'
    },
    {
        ID: '70',
        NAME: 'Navy Heather',
        CODE: 'navy_heather',
        HEX: '3e4a56'
    },
    {
        ID: '71',
        NAME: 'Dark Green',
        CODE: 'dark_green',
        HEX: '13220'
    },
    {
        ID: '72',
        NAME: 'Dark Grey',
        CODE: 'dark_grey',
        HEX: '63676a'
    },
    {
        ID: '73',
        NAME: 'Denim Blue',
        CODE: 'denim_blue',
        HEX: '6f8faf'
    },
    {
        ID: '74',
        NAME: 'Ice Grey',
        CODE: 'ice_grey',
        HEX: 'bec3c6'
    },
    {
        ID: '75',
        NAME: 'Banana',
        CODE: 'banana',
        HEX: 'fdf0c2'
    },
    {
        ID: '76',
        NAME: 'Berry',
        CODE: 'berry',
        HEX: 'c54874'
    },
    {
        ID: '77',
        NAME: 'Blossom',
        CODE: 'blossom',
        HEX: 'f1c5d6'
    },
    {
        ID: '78',
        NAME: 'Brick',
        CODE: 'brick',
        HEX: 'a8334d'
    },
    {
        ID: '79',
        NAME: 'Bright Salmon',
        CODE: 'bright_salmon',
        HEX: 'ec6c61'
    },
    {
        ID: '80',
        NAME: 'Burnt Orange',
        CODE: 'burnt_orange',
        HEX: 'eb763e'
    },
    {
        ID: '81',
        NAME: 'Chalky Mint',
        CODE: 'chalky_mint',
        HEX: '81bec3'
    },
    {
        ID: '82',
        NAME: 'Chambray',
        CODE: 'chambray',
        HEX: 'b9d7e2'
    },
    {
        ID: '83',
        NAME: 'Chili',
        CODE: 'chili',
        HEX: 'ad3852'
    },
    {
        ID: '84',
        NAME: 'Citrus',
        CODE: 'citrus',
        HEX: 'f3bb5b'
    },
    {
        ID: '85',
        NAME: 'Granite',
        CODE: 'granite',
        HEX: '808588'
    },
    {
        ID: '86',
        NAME: 'Grape',
        CODE: 'grape',
        HEX: '585280'
    },
    {
        ID: '87',
        NAME: 'Grass',
        CODE: 'grass',
        HEX: '209373'
    },
    {
        ID: '88',
        NAME: 'Grey',
        CODE: 'grey',
        HEX: 'a4a5a7'
    },
    {
        ID: '89',
        NAME: 'Heliconia',
        CODE: 'heliconia',
        HEX: 'f4317d'
    },
    {
        ID: '90',
        NAME: 'Hemp',
        CODE: 'hemp',
        HEX: '59634a'
    },
    {
        ID: '91',
        NAME: 'Ice Blue',
        CODE: 'ice_blue',
        HEX: '79949f'
    },
    {
        ID: '92',
        NAME: 'Ivory',
        CODE: 'ivory',
        HEX: 'f8efde'
    },
    {
        ID: '93',
        NAME: 'Kiwi',
        CODE: 'kiwi',
        HEX: '889e49'
    },
    {
        ID: '94',
        NAME: 'Lagoon Blue',
        CODE: 'lagoon_blue',
        HEX: '47c0d1'
    },
    {
        ID: '95',
        NAME: 'Light Green',
        CODE: 'light_green',
        HEX: '618e87'
    },
    {
        ID: '96',
        NAME: 'Melon',
        CODE: 'melon',
        HEX: 'f28d71'
    },
    {
        ID: '97',
        NAME: 'Midnight',
        CODE: 'midnight',
        HEX: '233758'
    },
    {
        ID: '98',
        NAME: 'Mustard',
        CODE: 'mustard',
        HEX: 'e0b863'
    },
    {
        ID: '99',
        NAME: 'Navy',
        CODE: 'navy',
        HEX: '1a213b'
    },
    {
        ID: '100',
        NAME: 'Orchid',
        CODE: 'orchid',
        HEX: 'a591b4'
    },
    {
        ID: '101',
        NAME: 'Peony',
        CODE: 'peony',
        HEX: 'd5508f'
    },
    {
        ID: '102',
        NAME: 'Red',
        CODE: 'red',
        HEX: 'ff050f'
    },
    {
        ID: '103',
        NAME: 'Royal Caribe',
        CODE: 'royal_caribe',
        HEX: '3688b8'
    },
    {
        ID: '104',
        NAME: 'Sage',
        CODE: 'sage',
        HEX: '767a69'
    },
    {
        ID: '105',
        NAME: 'Sandstone',
        CODE: 'sandstone',
        HEX: 'a29b8b'
    },
    {
        ID: '106',
        NAME: 'Seafoam',
        CODE: 'seafoam',
        HEX: '48a2a2'
    },
    {
        ID: '107',
        NAME: 'Topaz Blue',
        CODE: 'topaz_blue',
        HEX: '024862'
    },
    {
        ID: '108',
        NAME: 'Vineyard',
        CODE: 'vineyard',
        HEX: '744b59'
    },
    {
        ID: '109',
        NAME: 'Violet',
        CODE: 'violet',
        HEX: '686292'
    },
    {
        ID: '110',
        NAME: 'Washed Denim',
        CODE: 'washed_denim',
        HEX: '6581a8'
    },
    {
        ID: '111',
        NAME: 'Espresso',
        CODE: 'espresso',
        HEX: '40302c'
    },
    {
        ID: '112',
        NAME: 'Bay',
        CODE: 'bay',
        HEX: '8f9d94'
    },
    {
        ID: '113',
        NAME: 'Watermelon',
        CODE: 'watermelon',
        HEX: 'ce5769'
    }
];
